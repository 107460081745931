import { CardContent, Container } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'

const NoNews = () => {
  const { t } = useTranslations()

  return (
    <Container
      maxWidth='sm'
      sx={{
        mt: 4
      }}
    >
      <ContentContainer>
        <CardContent sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          {t('news.noNewsMessage')}
        </CardContent>
      </ContentContainer>
    </Container>
  )
}

export default NoNews
